<template>
  <div style="height: 100%">
    <nesi-user-common>
      <template #content>
        <el-col @click="queryGo" style="cursor: pointer; min-height: 600px;">
          <el-image style="width: 342px" :src="queryImg">
            <template #error>
              <div class="image-slot">
                <el-icon><icon-picture /></el-icon>
              </div>
            </template> </el-image
        ></el-col>
      </template>
    </nesi-user-common>
  </div>
</template>
<script setup>
import { defineComponent } from 'vue'
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
defineComponent({
  NesiUserCommon
})
const queryImg = require('@/assets/gongjijin.png')
const queryGo = () => {
  window.open('http://www.nngjj.org.cn/', '_blank')
}
</script>
<style lang="scss">

</style>
